import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import ReactModal from 'react-modal';
import Script from 'next/script';

import { setBodyNoScroll } from '../utils/common';
import ModalPageContent from '../components/ModalPageContent';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Modal from '../components/Modal';
import PlayerPopup from '../components/PlayerPopup';
import BackToTop from '../components/BackToTop';
import CookiesBanner from '../components/CookiesBanner';

import { playerModalGetOpen } from '../redux/modal/playerModal.selector';

import { initGTMconsent } from '../utils/gtm';
import { getCookieConsent } from '../redux/global/global.selector';
import { loadShareThisScript } from '../utils/sharethis';
import { loadAdditionalScript } from '../utils/additionalScript';

const Layout = props => {
    const router = useRouter();
    const state = useSelector(state => state);

    const isPlayerModalOpen = playerModalGetOpen(state);

    const {
        header,
        footer,
        ga_id: gaId,
        // search,
        footerGroup,
        cookies,
        firstCompType,
        googleMapKey,
        additionalTrackingScript
    } = props;

    const noSplash =
        firstCompType !== 'error' && firstCompType !== 'heroBanner' && firstCompType !== 'landingPageHeroBanner';

    const cookieAccept = getCookieConsent(state);
    const [hydrated, setHydrated] = useState(false);
    useEffect(() => {
        initGTMconsent();
        setHydrated(true);
    }, []);

    useEffect(() => {
        if (cookieAccept) {
            const tagManagerArgs = {
                gtmId: gaId,
            };
            TagManager.initialize(tagManagerArgs);
            loadShareThisScript();
        }
    }, [cookieAccept]);

    useEffect(() => {
        setBodyNoScroll(!!router.query.pageId);
        return () => {
            setBodyNoScroll(false);
        };
    }, [router.query.pageId]);

    useEffect(() => {
        // Add Baidu Analytics
        if (cookieAccept) {
            if (additionalTrackingScript) {
                loadAdditionalScript(additionalTrackingScript)
            }
        }
    }, [router, cookieAccept])

    return (
        <>
            <Script id='google-analytics' strategy='afterInteractive'>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                `}
            </Script>
            <Header {...header} socialMedia={footerGroup.socialMedia} noSplash={noSplash} />
            <main
                key={hydrated}
                className={['main-content', noSplash ? 'no-splash' : 'splash'].join(' ')}
                id='main-content'
            >
                {props.children}
            </main>
            <BackToTop />
            <Footer {...footer} {...footerGroup} />
            <Modal isOpen={isPlayerModalOpen} modalClass='player'>
                <PlayerPopup isModal />
            </Modal>
            <ReactModal
                isOpen={!!router.query.pageId}
                onRequestClose={() => router.back()}
                className='page-modal'
                overlayClassName='page-modal-overlay'
                contentLabel='Modal'
                closeTimeoutMS={800}
            >
                <button type='button' className='close-btn' onClick={() => router.back()}>
                    <span />
                    <span />
                </button>
                <ModalPageContent pagePath={router.query.pageId} googleMapKey={googleMapKey} />
            </ReactModal>
            <CookiesBanner {...cookies} />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    header: PropTypes.object,
    footer: PropTypes.object,
    footerGroup: PropTypes.object,
    cookies: PropTypes.object,
    ga_id: PropTypes.string,
    firstCompType: PropTypes.string,
    search: PropTypes.object,
    googleMapKey: PropTypes.string,
    additionalTrackingScript: PropTypes.string
};

Layout.defaultProps = {
    search: {
        link: '/search',
    },
};

export default Layout;

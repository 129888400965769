import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import style from './ErrorPage.module.scss';

const OverlayImage = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='592' height='316' fill='none' viewBox='0 0 592 316'>
        <path
            fill='#E67C25'
            d='M156.358 264.462c34.371-57.137-51.19-109.584-87.645-53.737L0 316h122.75c15.557-23.776 27.591-42.157 31.319-47.932.821-1.202 1.555-2.404 2.289-3.606zM473.946 76.75c33.021-57.401-52.276-108.353-88.261-52.77L196.717 316H319.79C387.24 211.751 462.734 94.955 471.657 80.561c.792-1.26 1.555-2.55 2.289-3.811zM591.999 11.316L394.637 316h122.28c23.452-36.176 49.047-75.665 75.082-115.799V11.316z'
        />
    </svg>
);

const ErrorPage = () => {
    return (
        <div className={style.el}>
            <div>
                <h1>404</h1>
                <h3>Page not found</h3>
                <Link href='/'>
                    <a className={style.button}>BACK TO HOME</a>
                </Link>
            </div>
            <div className={style.overlayImage}>
                <OverlayImage />
            </div>
        </div>
    );
};

ErrorPage.propTypes = {
    sitemap: PropTypes.array,
    logo: PropTypes.object,
    subscription: PropTypes.object,
    copyright: PropTypes.string,
    socialMedia: PropTypes.array,
};

ErrorPage.defaultProps = {
    logo: {
        url: '/images/logo-white.png',
        alt: '',
    },
    subscription: {
        title: 'Connect with us',
        fieldname: 'email',
        placeholder: 'EMAIL ADDRESS',
        errorText: 'please enter your email address',
        buttonLabel: 'SUBMIT',
    },
    copyright: '© 2021 Permira I.P Limited',
    sitemap: [
        {
            text: 'People',
            link: {
                url: '/people',
                target: '',
            },
            items: [
                {
                    text: 'Meet Our People',
                    link: {
                        url: '/meet-our-people',
                        target: '',
                    },
                },
                {
                    text: 'Culture and Values',
                    link: {
                        url: '/culture-and-values',
                        target: '',
                    },
                },
                {
                    text: 'Life at Permira',
                    link: {
                        url: '/life-at-permira',
                        target: '',
                    },
                },
            ],
        },
        {
            text: 'Responsibility',
            link: {
                url: '/responsibility',
                target: '',
            },
            items: [
                {
                    text: 'Investing Responsibly',
                    link: {
                        url: '/investing-responsibly',
                        target: '',
                    },
                },
                {
                    text: 'Permira Foundation',
                    link: {
                        url: '/permira-foundation',
                        target: '',
                    },
                },
            ],
        },
        {
            text: 'Investing',
            link: {
                url: '/investing',
                target: '',
            },
            items: [
                {
                    text: 'Private Equity Buyout',
                    link: {
                        url: '/private-equity-buyout',
                        target: '',
                    },
                },
                {
                    text: 'Private Equity Growth',
                    link: {
                        url: '/private-equity-growth',
                        target: '',
                    },
                },
                {
                    text: 'Credit',
                    link: {
                        url: '/credit',
                        target: '',
                    },
                },
            ],
        },
        {
            text: 'About',
            link: {
                url: '/about',
                target: '',
            },
            items: [
                {
                    text: 'Permira Today',
                    link: {
                        url: '/permira-today',
                        target: '',
                    },
                },
                {
                    text: 'Insights',
                    link: {
                        url: '/insights',
                        target: '',
                    },
                },
                {
                    text: 'Media',
                    link: {
                        url: '/media',
                        target: '',
                    },
                },
                {
                    text: 'In the News',
                    link: {
                        url: '/in-the-news',
                        target: '',
                    },
                },
            ],
        },
        {
            text: 'Portfolio',
            link: {
                url: '/portfolio',
                target: '',
            },
            items: [
                {
                    text: 'Our Portfolio',
                    link: {
                        url: '/our-portfolio',
                        target: '',
                    },
                },
                {
                    text: 'Technology',
                    link: {
                        url: '/technology',
                        target: '',
                    },
                },
                {
                    text: 'Consumer',
                    link: {
                        url: '/consumer',
                        target: '',
                    },
                },
                {
                    text: 'Services',
                    link: {
                        url: '/services',
                        target: '',
                    },
                },
                {
                    text: 'Healthcare',
                    link: {
                        url: '/healthcare',
                        target: '',
                    },
                },
            ],
        },
        {
            text: 'Others',
            link: {
                url: '/others',
                target: '',
            },
            items: [
                {
                    text: 'Goverance',
                    link: {
                        url: '/goverance',
                        target: '',
                    },
                },
                {
                    text: 'Legal',
                    link: {
                        url: '/legal',
                        target: '',
                    },
                },
                {
                    text: 'Privacy Policy',
                    link: {
                        url: '/privacy-policy',
                        target: '',
                    },
                },
                {
                    text: 'Cookie Notice',
                    link: {
                        url: '/cookie-notice',
                        target: '',
                    },
                },
                {
                    text: 'MSA',
                    link: {
                        url: '/msa',
                        target: '',
                    },
                },
                {
                    text: 'PGL',
                    link: {
                        url: '/pgl',
                        target: '',
                    },
                },
            ],
        },
    ],
    socialMedia: [
        {
            icon: 'wechat',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'vimeo',
            link: {
                target: '_blank',
                url: '#',
            },
        },
        {
            icon: 'youtube',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'twitter',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'linkedin',
            link: {
                target: '',
                url: '#',
            },
        },
    ],
};

export default ErrorPage;
